import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const Intro = ({ data }) => {
    return (
        <div
            className="intro-section section overlay"
            style={{
                backgroundImage: `url(${data.backgroundImage})`,
                objectFit: "cover",
            }}
        >
            <div className="container">
                <div className="row row-cols-lg-1 row-cols-1">
                    <div className="col align-self-center">
                        <div className="intro-content"></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

Intro.propTypes = {
    data: PropTypes.object,
};

export default Intro;
