const Map = () => {
    return (
        <iframe
            title="this is title"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d23858.44893692198!2d15.885896595114923!3d41.62751423220614!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13379b0f3f48d059%3A0x79ba7f59c9672ffe!2s71043%20Manfredonia%2C%20Province%20of%20Foggia%2C%20Italy!5e0!3m2!1sen!2sid!4v1681403706795!5m2!1sen!2sid"
        ></iframe>
    );
};

export default Map;
